<template>
  <v-card flat>
    <b-row>
      <b-col :md="4">
        <b-form-group
          id="name-group"
          v-bind:label="$t('FORM_LABELS.NAME')"
          label-for="name-input"
        >
          <FormInput
            id="name-input"
            v-model="form.name"
            type="text"
            name="name"
            :disabled="isReadonly"
            :i18n="{
              label: 'FORM_LABELS.NAME',
              placeholder: $t('FORM_LABELS.NAME')
            }"
            :validateState="validateState('name')"
          ></FormInput>
        </b-form-group>
      </b-col>
      <b-col :md="4">
        <b-form-group
          id="period-type-group"
          v-bind:label="$t('FORM_PLACEHOLDERS.PERIOD_TYPE')"
          label-for="period-type-input"
        >
          <FormSelect
            id="period-type-input"
            v-model="form.periodType"
            type="select"
            clearable
            :options="periodTypes"
            :disabled="isReadonly"
            :validateState="validateState('periodType')"
          ></FormSelect>
        </b-form-group>
      </b-col>
      <b-col :md="4">
        <b-form-group
          id="item-serviceGroups-group"
          :label="$t('FORM_LABELS.SERVICE_GROUP')"
          label-for="item-serviceGroups-input"
        >
          <FormSelect
            clearable
            type="select"
            :options="serviceGroups"
            v-model="form.serviceGroupId"
          ></FormSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="form.periodType && form.periodType !== 'none'">
      <ServiceTypeLineAdd
        :value="defaultValues"
        :item="{ name: 'Въведи за всички типове' }"
        :type="form.periodType"
      />
      <h3 class="my-4">Изберете периодичност по тип машина</h3>
      <ServiceTypeLineAdd
        v-for="type in machineTypes"
        :key="type.id"
        :value="form.periods[type.id]"
        :item="type"
        :type="form.periodType"
      />
    </div>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters, mapState } from "vuex";
import { FETCH_MACHINES_TYPES } from "@/modules/machines-types/store/machinetype.module";
import { FETCH_SERVICE_GROUPS } from "@/modules/service-groups/store/service-groups.module";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import ServiceTypeLineAdd from "./ServiceTypeLineAdd.vue";

export default {
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormInput,
    FormSelect,
    ServiceTypeLineAdd
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false
    },
    form: Object
  },
  data() {
    return {
      errors: {},
      firstLoader: true,
      machineTypes: [],
      defaultValues: {
        periodValue: null,
        periodMonths: null,
        periodDays: null
      },
      serviceGroups: []
    };
  },
  validations() {
    const tmpValidationObject = {
      form: {
        name: this.setItemValidations({
          required: true
        }),
        periodType: this.setItemValidations({
          required: true
        })
      }
    };

    return tmpValidationObject;
  },
  computed: {
    ...mapGetters(["isLoadingServiceTypes"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    periodTypes() {
      return [
        {
          id: "km",
          name: this.$t("LABELS.PERIOD_TYPE_KM")
        },
        {
          id: "hours",
          name: this.$t("LABELS.PERIOD_TYPE_HOURS")
        },
        {
          id: "kmortime",
          name: this.$t("LABELS.PERIOD_TYPE_KMORTIME")
        },
        {
          id: "hoursortime",
          name: this.$t("LABELS.PERIOD_TYPE_HOURSORTIME")
        },
        {
          id: "time",
          name: this.$t("LABELS.PERIOD_TYPE_TIME")
        },
        {
          id: "none",
          name: this.$t("LABELS.PERIOD_TYPE_NONE")
        }
      ];
    }
  },
  watch: {
    "defaultValues.periodValue": {
      handler() {
        const pkey = "periodValue";
        Object.keys(this.form.periods).forEach(key => {
          this.form.periods[key][pkey] = this.defaultValues[pkey];
        });
      }
    },
    "defaultValues.periodMonths": {
      handler() {
        const pkey = "periodMonths";
        Object.keys(this.form.periods).forEach(key => {
          this.form.periods[key][pkey] = this.defaultValues[pkey];
        });
      }
    },
    "defaultValues.periodDays": {
      handler() {
        const pkey = "periodDays";
        Object.keys(this.form.periods).forEach(key => {
          this.form.periods[key][pkey] = this.defaultValues[pkey];
        });
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.firstLoader = false;
    });

    this.fetchMachineTypes();
    this.fetchGroups();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    fetchMachineTypes() {
      this.$store
        .dispatch(
          FETCH_MACHINES_TYPES,
          this.$url.transformParams({ onlySelectValues: true })
        )
        .then(data => {
          this.machineTypes = data.data.items;
          this.machineTypes.forEach(type => {
            if (!this.form.periods[type.id]) {
              this.$set(this.form.periods, type.id, {
                periodValue: null,
                periodMonths: null,
                periodDays: null
              });
            }
          });
        });
    },
    fetchGroups() {
      this.$store
        .dispatch(
          FETCH_SERVICE_GROUPS,
          this.$url.transformParams({ onlySelectValues: true })
        )
        .then(data => {
          this.serviceGroups = data.data.items;
        });
    }
  }
};
</script>
