<template>
  <b-row>
    <b-col :cols="2">
      <b>{{ item.name }}:</b>
    </b-col>
    <b-col v-if="hasPeriodValue" :cols="5">
      <b-form-group
        id="periodValue-group"
        :label="
          type.includes('km')
            ? $t('FORM_LABELS.PERIOD_KM')
            : $t('FORM_LABELS.PERIOD_HOURS')
        "
        label-for="periodValue-input"
      >
        <FormInput
          id="periodValue-input"
          v-model="value.periodValue"
          type="text"
          name="periodValue"
          :i18n="{
            label: 'FORM_LABELS.PERIOD_VALUE',
            placeholder: $t('FORM_LABELS.PERIOD_VALUE')
          }"
        ></FormInput>
      </b-form-group>
    </b-col>
    <b-col v-if="hasTime" :cols="5">
      <b-form-group
        id="periodTime-group"
        v-bind:label="$t('FORM_LABELS.PERIOD_TIME')"
        label-for="periodTime-input"
      >
        <b-row>
          <b-col>
            <FormInput
              id="periodMonths-input"
              v-model="value.periodMonths"
              type="text"
              name="periodMonths"
              :i18n="{
                label: 'FORM_LABELS.MONTHS',
                placeholder: $t('FORM_LABELS.MONTHS')
              }"
            ></FormInput>
          </b-col>
          <b-col>
            <FormInput
              id="periodDays-input"
              v-model="value.periodDays"
              type="text"
              name="periodDays"
              :i18n="{
                label: 'FORM_LABELS.DAYS',
                placeholder: $t('FORM_LABELS.DAYS')
              }"
            ></FormInput>
          </b-col>
        </b-row>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";

export default {
  components: {
    FormInput
  },
  props: {
    type: String,
    item: Object,
    value: Object
  },
  computed: {
    hasPeriodValue() {
      return this.type.includes("km") || this.type.includes("hours");
    },
    hasTime() {
      return this.type.includes("time");
    }
  }
};
</script>

<style></style>
