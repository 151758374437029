var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":2}},[_c('b',[_vm._v(_vm._s(_vm.item.name)+":")])]),(_vm.hasPeriodValue)?_c('b-col',{attrs:{"cols":5}},[_c('b-form-group',{attrs:{"id":"periodValue-group","label":_vm.type.includes('km')
          ? _vm.$t('FORM_LABELS.PERIOD_KM')
          : _vm.$t('FORM_LABELS.PERIOD_HOURS'),"label-for":"periodValue-input"}},[_c('FormInput',{attrs:{"id":"periodValue-input","type":"text","name":"periodValue","i18n":{
          label: 'FORM_LABELS.PERIOD_VALUE',
          placeholder: _vm.$t('FORM_LABELS.PERIOD_VALUE')
        }},model:{value:(_vm.value.periodValue),callback:function ($$v) {_vm.$set(_vm.value, "periodValue", $$v)},expression:"value.periodValue"}})],1)],1):_vm._e(),(_vm.hasTime)?_c('b-col',{attrs:{"cols":5}},[_c('b-form-group',{attrs:{"id":"periodTime-group","label":_vm.$t('FORM_LABELS.PERIOD_TIME'),"label-for":"periodTime-input"}},[_c('b-row',[_c('b-col',[_c('FormInput',{attrs:{"id":"periodMonths-input","type":"text","name":"periodMonths","i18n":{
              label: 'FORM_LABELS.MONTHS',
              placeholder: _vm.$t('FORM_LABELS.MONTHS')
            }},model:{value:(_vm.value.periodMonths),callback:function ($$v) {_vm.$set(_vm.value, "periodMonths", $$v)},expression:"value.periodMonths"}})],1),_c('b-col',[_c('FormInput',{attrs:{"id":"periodDays-input","type":"text","name":"periodDays","i18n":{
              label: 'FORM_LABELS.DAYS',
              placeholder: _vm.$t('FORM_LABELS.DAYS')
            }},model:{value:(_vm.value.periodDays),callback:function ($$v) {_vm.$set(_vm.value, "periodDays", $$v)},expression:"value.periodDays"}})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }